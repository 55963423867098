<script setup lang="ts">
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { isEmpty } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { NearestCountries } from '#types/general';
import {
  useImage,
  useIntersectionObserver,
  ref,
  useDevice,
  useGeneralService,
  useNavigation,
  useRuntimeConfig,
} from '#imports';

import 'swiper/css';
import 'swiper/css/navigation';

const modules = [Navigation];

const img = useImage();
const runtime = useRuntimeConfig();
const { country: configCountry } = storeToRefs(useConfigStore());
const { locale } = useI18n();
const { navigationPath } = useNavigation();

const device = useDevice();

const {
  data: countries,
  pending,
  execute: fetchHotelPopularDestination,
} = await useAsyncData(
  () =>
    useGeneralService().getNearestCountries({
      country: configCountry.value!,
      lang: locale.value,
    }),
  {
    immediate: device.isCrawler,
    transform: (data) => (data.result as NearestCountries).countries,
  },
);

const hotelRecommendationRef = ref<HTMLElement | null>(null);
const initialState = ref(!device.isCrawler);

function getHotelByCountryPath(country: { enName: string; code: string }) {
  return navigationPath({
    path:
      '/hotel/country/' + normalizeSlug(`${country.enName.replace(' ', '-')}` + '-' + country.code.toLowerCase(), true),
  });
}

function getCountryImage(country: string) {
  const countryName = country.split(' ').join('-').toLowerCase();

  if (!countryName) {
    return '';
  }

  return `${runtime.public.cdnAssetUrl}/countries/${countryName}.png`;
}

const { stop } = useIntersectionObserver(
  hotelRecommendationRef,
  // @ts-expect-error
  ([{ isIntersecting }]) => {
    if (isIntersecting && !device.isCrawler) {
      fetchHotelPopularDestination();

      initialState.value = false;

      stop();
    }
  },
);
</script>

<template>
  <div ref="hotelRecommendationRef">
    <h2 class="text-extra font-bold mb-30">{{ $t('hotelstaticpage.hotelcountryselection') }}</h2>

    <HotelRecommendationPlaceholder v-if="pending || initialState" />

    <div
      v-else-if="!isEmpty(countries)"
      class="w-full relative"
    >
      <Swiper
        :slides-per-view="4"
        :space-between="10"
        :lazy="!device.isCrawler"
        loop
        :navigation="{
          nextEl: '.nearest-country-button-next',
          prevEl: '.nearest-country-button-prev',
        }"
        :modules="modules"
        class="swiper w-full rounded"
      >
        <SwiperSlide
          v-for="(country, index) in countries"
          :key="index"
          class="w-auto relative shadow-md hover:cursor-pointer"
        >
          <NuxtLink
            :to="getHotelByCountryPath(country)"
            external
            no-rel
          >
            <NuxtImg
              :src="getCountryImage(country.enName)"
              :alt="country.name || `Hotel Images_${index}`"
              format="webp"
              height="235"
              width="267"
              class="aspect-[267/235] rounded"
              :loading="index > 3 ? 'lazy' : 'eager'"
              fit="cover"
              sizes="lg:534px"
              :placeholder="
                img('/img/placeholder.png', {
                  sizes: 'lg:534px',
                  modifiers: {
                    format: 'webp',
                    quality: 70,
                    height: 235,
                    width: 267,
                  },
                })
              "
            />

            <div class="absolute inset-0 bg-gradient-to-t from-[#000000cc] to-[#69696900] rounded">
              <div class="absolute left-20 bottom-20 flex flex-col gap-y-5 text-white rounded">
                <h3 class="font-bold text-medium">
                  {{ country.name }}
                </h3>

                <p class="line-clamp-1 text-small text-white">
                  {{ $t('hotel.properties', { value: `${country.count || 0}+` }) }}
                </p>
              </div>
            </div>
          </NuxtLink>
        </SwiperSlide>
      </Swiper>

      <ApzButton class="swiper-button-next nearest-country-button-next hover:!bg-gray-lightest" />
      <ApzButton class="swiper-button-prev nearest-country-button-prev hover:!bg-gray-lightest" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.swiper-button-next,
.swiper-button-prev {
  @apply rounded-full text-gray-darkest bg-white top-[calc(235px/2)] -translate-y-15 my-auto shadow-md;

  height: 48px;
  width: 48px;

  &::after {
    @apply font-bold;

    font-size: 18px;
  }
}

.swiper-button-prev {
  @apply -left-[24px];
}

.swiper-button-next {
  @apply -right-[24px];
}

.swiper-lazy-preloader {
  @apply border-x-primary border-b-primary;
}
</style>
